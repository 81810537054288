exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-templates-all-results-page-js": () => import("./../../../src/templates/all-results-page.js" /* webpackChunkName: "component---src-templates-all-results-page-js" */),
  "component---src-templates-all-videos-page-js": () => import("./../../../src/templates/all-videos-page.js" /* webpackChunkName: "component---src-templates-all-videos-page-js" */),
  "component---src-templates-article-page-js": () => import("./../../../src/templates/article-page.js" /* webpackChunkName: "component---src-templates-article-page-js" */),
  "component---src-templates-articles-page-js": () => import("./../../../src/templates/articles-page.js" /* webpackChunkName: "component---src-templates-articles-page-js" */),
  "component---src-templates-board-chart-page-js": () => import("./../../../src/templates/board-chart-page.js" /* webpackChunkName: "component---src-templates-board-chart-page-js" */),
  "component---src-templates-boutique-page-tsx": () => import("./../../../src/templates/boutique-page.tsx" /* webpackChunkName: "component---src-templates-boutique-page-tsx" */),
  "component---src-templates-contact-page-js": () => import("./../../../src/templates/contact-page.js" /* webpackChunkName: "component---src-templates-contact-page-js" */),
  "component---src-templates-content-page-js": () => import("./../../../src/templates/content-page.js" /* webpackChunkName: "component---src-templates-content-page-js" */),
  "component---src-templates-index-page-tsx": () => import("./../../../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-result-page-js": () => import("./../../../src/templates/result-page.js" /* webpackChunkName: "component---src-templates-result-page-js" */),
  "component---src-templates-results-page-js": () => import("./../../../src/templates/results-page.js" /* webpackChunkName: "component---src-templates-results-page-js" */),
  "component---src-templates-tournament-page-js": () => import("./../../../src/templates/tournament-page.js" /* webpackChunkName: "component---src-templates-tournament-page-js" */)
}

